/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Accordion from '../components/Common/Accordion';

const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 500px;

  .container__text,
  .container__image {
    width: 100%;
  }

  .container__image {
    order: 1;
    position: relative;
    z-index: 1;
    text-align: center;
  }

  .gatsby-image-wrapper {
    max-width: 450px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;

    .container__text {
      width: calc(100% - 480px);
    }

    .container__image {
      margin: 0 0 20px 30px;
      max-width: 450px;
      width: 100%;
    }
  }
`;

// https://auto-animate.formkit.com/#usage
// https://github.com/briancodex/react-accordion-v1/blob/main/src/components/Accordion.js

const AccordionData = [
  {
    question: '<p>Ile kosztuje wycena monet lub banknotów?</p>',
    answer: '<p>Zarówno wycena, jak i konsultacja jest <strong>całkowicie darmowa</strong> i nie zobowiązuje do&nbsp;sprzedaży.</p>',
  },
  {
    question: '<p>Jak przygotować numizmaty do wyceny?</p>',
    answer: '<p>Najłatwiej jest zrobić zdjęcie zbiorcze monet lub banknotów. Nie ma potrzeby robić oddzielnych zdjęć dla poszczególnych monet. Tak&nbsp;przygotowane zdjęcia proszę wysłać na <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>. Na&nbsp;podstawie wysłanych zdjęć jesteśmy w stanie wstępnie oszacować wartość kolekcji.</p>',
  },
  {
    question: '<p>Czy jesteście na Facebooku?</p>',
    answer: '<p>Tak, posiadamy swój profil: <a href="https://www.facebook.com/skup.monet.olsztyn" rel="nofollow">Monety i banknoty Olsztyn</a>, na ktorym prezentujemy ciekawostki ze świata numizmatyki.<br /> Zachęcamy do obserwowania naszego profilu!</p>',
  },
];

const siteMetadata = {
  title: 'Skup starych monet, banknotów, numizmatów - Faq',
  description: 'Bezpłatna wycena numizmatów, srebrne monety kolekcjonerskie, stare monety i banknoty z okresu międzywojennego.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/faq/',
  locale: 'pl_PL',
};

const FaqPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <h1>FAQ</h1>

    <p style={{ margin: '0 0 3rem' }}>Najczęściej zadawane pytania:</p>

    <ContainerWrap>
      <div className="container__image">
        <GatsbyImage
          image={getImage(data.ii_rp_coins)}
          alt="Paleta z czerwonym suknem, na której znajdują się polskie monety II RP"
        />
        <figcaption>Polskie obiegowe monety z&nbsp;okresu II RP</figcaption>
      </div>

      <div className="container__text">
        <Accordion data={AccordionData} />
      </div>
    </ContainerWrap>
  </>
);

export const query = graphql`
  query {
    ii_rp_coins: file(relativePath: {regex: "/prezentacja-starych-monet/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 450
        )
      }
    }
  }
`;

export default FaqPage;
